<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();

const props = defineProps<{
	itemSection: number;
}>();

const { pageData, lastGames } = useHomePage();
const isGuest = useIsGuest();
const { select } = useGamesState();

const isPrerenderedComponent = (count: number) => count < 2;
const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);
</script>

<template>
	<template v-for="(game, index) in games" :key="index">
		<OLazyComponent
			v-if="game?.slug === 'last' && !isGuest && lastGames?.length"
			id="home-game-last"
			:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			class="category-section"
		>
			<div class="section-title" data-tid="slider-title-recently-played">
				<AText :size="{ full: 40, md: 24 }" :modifiers="['medium']">{{ t("Your") }}</AText>
				<AText
					gradient="var(--ghaziabad)"
					:modifiers="['bold', 'uppercase']"
					class="text-tlalnepantla"
					:size="{ full: 56, md: 32 }"
				>
					<span>{{ t("LAST GAMES") }}</span>
				</AText>
			</div>
			<LazyOHomeGamesSlider :rows-per-slide="1" :games="lastGames || []" />
		</OLazyComponent>
		<OLazyComponent
			v-else-if="!!pageData?.games[camelCase(game?.slug ?? '')]?.length"
			:id="`home-game-${game?.slug}`"
			:prerender="itemSection === 0 ? isPrerenderedComponent(index) : false"
			class="category-section"
		>
			<div class="title-wrapper">
				<div class="section-title" data-tid="slider-title-games">
					<ASvg name="24/title-star" class="icon icon-star" />
					<ASvg name="24/title-star-blur" class="icon icon-star-blur" />
					<AText :size="{ full: 40, md: 24 }" :modifiers="['medium']">{{ t("Check our") }}</AText>
					<AText
						gradient="var(--ghaziabad)"
						:modifiers="['bold', 'uppercase']"
						class="text-tlalnepantla"
						:size="{ full: 56, md: 32 }"
					>
						<span>{{ pageData?.gamesInfo[camelCase(game?.slug ?? "")]?.title || "" }}</span>
					</AText>
				</div>

				<NuxtLink v-if="game?.slug === 'all-games'" class="search" @click="open('LazyOModalGameSearch')">
					<NuxtIcon class="icon" name="24/search" filled />
					<AText :size="16" :modifiers="['semibold']" class="text-tlalnepantla">{{ t("Find your game") }}</AText>
				</NuxtLink>
			</div>

			<LazyOHomeGamesSlider
				:rows-per-slide="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.rowsPerSlide"
				:games="select(pageData?.games[camelCase(game?.slug ?? '')])"
				:link="game?.slug === 'all-games'"
			/>
		</OLazyComponent>
	</template>
</template>

<style lang="scss" scoped>
.category-section {
	margin: 96px 0 8px;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		margin-top: -10px;
		padding-top: 50px;
	}

	&:deep(section) {
		overflow: hidden;
		padding: 0 1px;
		max-width: calc(100% - 30px);
	}
}

.title-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.section-title {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 32px;
	gap: 0 16px;
	position: relative;
	padding: 0 16px;

	@include media-breakpoint-down(md) {
		margin-bottom: 16px;
	}

	.icon-star {
		position: absolute;
		font-size: 41px;
		right: 100px;
		top: 40px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.icon-star-blur {
		position: absolute;
		font-size: 82px;
		right: 130px;
		top: -13px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.search {
	margin-bottom: 32px;
	width: 328px;
	max-width: calc(100% - 32px);
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	color: var(--cannes);
	padding: gutter(1.25);
	text-decoration: none;
	border-radius: 999px;
	border: 2px solid var(--celaya);
	transition: all 200ms ease-in-out;
	.icon {
		margin-right: 8px;
		:deep(svg) {
			display: inline-block;
			vertical-align: middle;
		}
	}
	&:hover {
		cursor: pointer;
		border: 2px solid var(--сirebon);
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 16px;
	}
}

.event-icon {
	position: absolute;
	font-size: 99px;

	@include media-breakpoint-down(md) {
		font-size: 54px;
		bottom: 30px;
	}

	&.left {
		left: 130px;

		@include media-breakpoint-down(lg) {
			left: 20px;
		}
	}

	&.right {
		right: 130px;
		top: -40px;

		@include media-breakpoint-down(lg) {
			right: 0;
			top: -50px;
		}
	}

	&.casper {
		width: 101px;
		height: 95px;
		top: -38px;

		@include media-breakpoint-down(lg) {
			width: 50px;
			height: 47px;
		}
	}

	&.web {
		width: 116px;
		height: 110px;

		@include media-breakpoint-down(lg) {
			width: 58px;
			height: 55px;
		}
	}

	&.booo {
		width: 118px;
		height: 110px;

		@include media-breakpoint-down(lg) {
			width: 59px;
			height: 55px;
		}
	}

	&.pumpkin {
		width: 137px;
		height: 135px;

		@include media-breakpoint-down(lg) {
			width: 68px;
			height: 66px;
		}
	}

	&.vampire {
		width: 115px;
		height: 93px;

		@include media-breakpoint-down(lg) {
			width: 57px;
			height: 46px;
		}
	}

	&.bat {
		width: 190px;
		height: 152px;

		@include media-breakpoint-down(lg) {
			width: 80px;
			height: 76px;
		}
	}

	&.bone {
		width: 108px;
		height: 82px;

		@include media-breakpoint-down(lg) {
			width: 54px;
			height: 41px;
		}
	}
}
</style>
